import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import { localGet } from '../lib/session'
import PERMISSION from '../const/userPermission'
import { AppBreadcrumb, AppFooter, AppHeader, AppSidebarNav as AppMenuNav } from '@coreui/react'
import navigation from '../routes/nav'
import routes from '../routes/routes'
import DefaultFooter from './defaultFooter'
import DefaultHeader from './defaultHeader'
import { FeeChangeNotice } from '../components/FeeChangeNotice'
import AlertStrapiBanner from '../components/alertStrapiBanner'

class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDataLoaded: false,
      filteredNavigation: []
    }
    this.updateNavigation = this.updateNavigation.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.listBranch?.data && this.props.listBranch?.data) {
      this.updateNavigation()
    }
  }

  updateNavigation() {
    const { listBranch } = this.props

    if (!listBranch?.data) return

    const hasDesconto = listBranch.data.some((branch) =>
      branch.OptIns?.includes(process.env.REACT_APP_OPTIN_DESCONTO)
    )

    const hasCashback = listBranch.data.some((branch) =>
      branch.OptIns?.includes(process.env.REACT_APP_OPTIN_CASHBACK)
    )


    let navigationItems = [...navigation.items.filter((item) => item.url !== '/gestao-de-campanhas')]

    if (hasDesconto || hasCashback) {
      navigationItems.push({
        name: 'A Oferta é Sua!',
        url: '/gestao-de-campanhas',
        icon: 'fa fa-balance-scale',
        badge: { variant: 'info' }
      })
    }

    this.setState({
      filteredNavigation: navigationItems,
      isDataLoaded: true
    })

  }

  render() {
    const { authUser, location, ...rest } = this.props
    const { readOnly } = authUser


    let redirect = { from: '/', to: '/busca-parceiro' }
    const authLocal = localGet('AUTH_TOKEN')

    if (!!authLocal && !authLocal.Authorized && authLocal.Type === PERMISSION.portal) {
      redirect = { from: '/', to: '/em-breve' }
      return <Redirect to={{ pathname: '/em-breve' }} />
    }

    if (!readOnly) {
      redirect = { from: '/', to: '/vendas-hoje' }
    }

    return (
      <div className="app">
        <FeeChangeNotice />
        <AppHeader fixed>
          <DefaultHeader>
            <AppMenuNav
              className="app-header-menu nav-link-custom"
              navConfig={{ items: this.state.filteredNavigation }}
              {...rest}
            />
          </DefaultHeader>
        </AppHeader>
        <div className="app-body">
          <main className="main">
            {location.pathname !== '/busca-parceiro' && (
              <Container>
                <AlertStrapiBanner />
              </Container>
            )}
            <AppBreadcrumb
              className="app-breadcrumb container px-0 border-bottom-0"
              appRoutes={routes}
            />
            <Container>
              <Switch>
                {routes.map((route) =>
                  route.component ? (
                    <Route
                      key={route.name}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null
                )}
                <Redirect {...redirect} />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter className="mt-5 bg-primary">
          <DefaultFooter />
        </AppFooter>
      </div>
    )
  }
}

const mapStateToProps = ({ userState, branchState }) => ({
  authUser: userState.authUser,
  listBranch: branchState.listBranch
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout))
