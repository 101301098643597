import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import moment from 'moment'
import IconFaq from '../components/icons/faq'
import IconPhone from '../components/icons/phone'
import copyToClipboard from '../lib/copyToClipboard'

function DefaultFooter() {
  return (
    <Container className="px-0">
      <Row className="pt-5 pb-4">
        <Col md="6" className="pl-4">
          <img style={{ width: '90px', height:'90px' }} src="/assets/imgs/logo_transparent.png" alt="KMV" />
        </Col>

        <Col md="3" />

        <Col md="3" className="help-center">
          <p className="text-white-50">Central de Ajuda</p>

          <div className="pl-4">
            <button
              type="button"
              className="chat px-0 border-0 bg-transparent"
              // onClick={() => window.openChatbot()}
            >
              <IconFaq color="#fff" size="22" />
              <b>Me ajuda</b>
            </button>
            <p>24h, todos os dias</p>
          </div>

          <div className="pl-4">
            <button
              type="button"
              className="text-left phone px-0 border-0 bg-transparent"
              onClick={() => copyToClipboard('2920-5153')}
            >
              <IconPhone size="14" />
              <b>(11) 2920-5153 <br /> <IconPhone size="14" />0800 286 5556</b> (exclusivo para telefone fixo)
            </button>
            <p className="mb-0">Segunda a sexta, das 6h às 22h</p>
            <p>Sábados das 06h às 17h</p>
          </div>
        </Col>
      </Row>

      <Row className="copy mx-3 py-4">
        <Col className="px-0">
          <p className="text-white-50 mb-0">
            {`Copyright © ${moment().format('YYYY')} KMV. Todos os direitos reservados.`}
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default DefaultFooter
