export default {
  TITLE_PAGE: 'Meus dados',
  TABS: [
    {
      id: 1,
      label: 'Cadastrais'
    },
    {
      id: 2,
      label: 'Financeiros'
    },
    {
      id: 4,
      label: 'Notas Fiscais'
    },
    // {
    //   id: 5,
    //   label: 'INFORME DE RENDIMENTOS'
    // },
    {
      id: 5,
      label: 'TERMOS E CONDIÇÕES'
    },
  ],
  REQUEST_MSG: {
    ERROR: 'Falha ao consultar seus dados'
  },
  NAME_BUTTON: 'Baixar termos e condições',
  DETAILS_CONFIG: {
    boxTitle: 'Formas de pagamento, comissões e liquidações',
    header: [
      'Forma de pagamento',
      'Taxa de comissão',
      'Prazo do repasse',
    ]
  },
  REGISTRATION: {
    IDENTIFICATION: {
      TITLE: 'Informações de identificação'
    },
    STORE_DATA: {
      HEAD_TITLE: 'Dados da Matriz',
      BRANCH_TITLE: 'Dados da Loja'
    },
    STORE_ASSOCIATED: {
      TITLE: 'Demais lojas associadas ao login'
    },
    ALERT: 'Não conseguimos encontrar os dados desta loja para mostrar',
    CONTROL_PAGES: {
      PAGE_SIZE: [10, 20, 30, 40, 50, 100]
    },
    LABELS: {
      year: 'Ano de referência',
      email: 'E-mail de acesso',
      integratorCode: 'Código da operadora',
      redirectUrl: 'URL do site',
      document: 'CNPJ',
      phone: 'Telefone',
      fantasyName: 'Nome Fantasia',
      address: 'Endereço',
      name: 'Razão social',
      zipcode: 'CEP',
      storeOwner: 'Responsável',
      bankData: 'Dados bancários',
      location: 'Latitude e Longitude'
    }
  }
}
